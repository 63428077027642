import { useEffect, useState } from "react";

const FooterLayout = () => {
  const descp =
    "OneMedHQ uses tech to unite healthcare stakeholders for ongoing improvement in healthcare experiences.";

  const descpOne =
    "OneMedHQ is dedicated to providing a seamless cross-border avenue for individuals to access top-tier medical professionals.";

  const despTwo =
    "OneMedHQ enables seamless communication between healthcare practitioners.";

  const despThree =
    "OneMedHQ empowers patients to seek second opinions from physicians with ease and in expedited 3 step process";

  const [desc, setDesc] = useState(descp);
  useEffect(() => {
    if (window.location.href.includes("advantages")) {
      setDesc(descpOne);
    }
    if (window.location.href.includes("About-Us")) {
      setDesc(despTwo);
    }
    if (window.location.href.includes("contact/us")) {
      setDesc(despThree);
    }
  }, []);

  return (
    <div className="footer2">
      <div className="wrap">
        <div className="box_1">
          <h3>{desc}</h3>
        </div>
      </div>
    </div>
  );
};

export default FooterLayout;
