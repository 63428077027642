import React from "react";
// import {Link} from 'react-router-dom';
import { Col, Row } from "react-bootstrap";
import InnerBanner from "../innerBanner";
import PageHeading from "../pageHeading";
import card1 from "../../assets/img/comp-2.png";
import card2 from "../../assets/img/Mask_Group.png";
import Bottom from "../home/Bottom";
import acrossGlobe from "../../assets/img/onemedhq_images/compitativeAdvantageBottomImage.jpg";

import BottomCard from "../bottomCard";
import advantages_banner from "../../assets/img/onemedhq_images/compitativeAdvantageRightTop.jpg";
import growth from "../../assets/img/growth.png";
import Loader from "../layout/loader";
import PrimaryBgHeading from "../primaryBgHeading";
import advanBanner from "../../assets/img/onemedhq_images/compotetiveBanner.jpg";
import { useNavigate } from "react-router-dom";
import leftImage from "../../assets/img/AboutUsLeft.png";
import FooterLayout from "../layout/footer/footerLayout";

const Advantages = () => {
  const navigate = useNavigate();

  const descp =
    "OneMedHQ is dedicated to providing a seamless cross-border avenue for individuals to access top-tier medical professionals.";
  return (
    <>
      <Loader />
      <div className="advantages-wrap">
        <InnerBanner
          // data-aos-duration="700"
          data-aos="fade-up"
          // data-aos-delay="1000"
          title="WHY US"
          image={advanBanner}
        />
        <div className="row page-heading-wrap">
          <div className="cal-12">
            <p
              // data-aos-duration="700"
              data-aos="fade-left"
              // data-aos-delay="1000"
              className="text pt-5"
            ></p>
          </div>
          <div className="top-advantage-section">
            <div className="row">
              <div className="col-lg-7 col-sm-12 col-12">
                <div
                  // data-aos-duration='700'
                  data-aos="fade-right"
                  // data-aos-delay='1000'
                >
                  <p className="text ">
                    OneMedHQ leads the transformation of healthcare technology,
                    facilitating seamless collaboration between doctors and
                    patients. Our platform unifies healthcare professionals,
                    optimizing the patient experience with unparalleled
                    efficiency. Renowned globally for healthcare integration, we
                    prioritize connectivity, streamlining processes, and
                    alleviating administrative burdens. Our commitment lies in
                    fostering stakeholder collaboration, driving operational
                    efficiency, and ultimately enhancing patient outcomes within
                    a harmonized healthcare ecosystem.
                  </p>
                  <div>
                    <p className="text ">
                      OneMedHQ uses advanced technology to streamline
                      healthcare, reduce administrative burdens, and foster
                      collaboration, ensuring efficient decision-making,
                      improved patient outcomes, and a unified healthcare
                      ecosystem.
                    </p>
                  </div>
                </div>
              </div>
              <div
                // data-aos-duration="700"
                data-aos="fade-up"
                // data-aos-delay="1000"
                className="col-lg-5 col-sm-12 col-12 d-flex justify-content-end"
              >
                <img src={advantages_banner} className="w-75" />
              </div>
            </div>
          </div>
        </div>
        {/* <div className='bg-focus mt-5 pb-5'>
          <div className=''>
            <div className='banner_head'>
              <div className='row'>
                <div className='col-12'>
                  <div className='tech_'>
                    <div className='text-center'>
                      <span className='title_1'>TECHNOLOGY WE FOCUS IN</span>
                      <h6
                        data-aos-duration='700'
                        data-aos='fade-right'
                        data-aos-delay='1000'
                        className='com-title'
                      >
                        OneMedHQ Operating Advantages
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-md-6 col-sm-12 col-12'>
                  <div className='card h-100'>
                    <img
                      data-aos-duration='700'
                      data-aos='fade-up'
                      data-aos-delay='1000'
                      src={card1}
                      className='card-img-top'
                      alt='...'
                    />
                    <div className='card-body card__'>
                      <h5
                        data-aos-duration='700'
                        data-aos='fade-up'
                        data-aos-delay='1000'
                        className='card-title text-left'
                      >
                        Organic Rankine Cycle (ORC) Plant
                      </h5>

                      <p className='card-text text-left focus-text'>
                        OneMedHQ Holdings uses state-of-the-art compact Organic
                        Rankine Cycle (ORC) OneMedHQ power plants that optimize
                        low-temperature OneMedHQ resources for maximum power
                        production and 98% uptime efficiency.
                      </p>
                      <span
                        className='cursor-pointer'
                        onClick={() => navigate('/technology/orc')}
                      >
                        {' '}
                        Know More
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-sm-12 col-12 mt-4 mt-md-0'>
                  <div className='card h-100'>
                    <img
                      data-aos-duration='700'
                      data-aos='fade-up'
                      data-aos-delay='1000'
                      src={card2}
                      className='card-img-top'
                      alt='...'
                    />
                    <div className='card-body card__'>
                      <h5
                        data-aos-duration='700'
                        data-aos='fade-left'
                        data-aos-delay='1000'
                        className='card-title text-left'
                      >
                        Technology
                      </h5>
                      <p className='card-text  focus-text'>
                        Technology power plants transfer the heat from OneMedHQ
                        hot water to another liquid. It represents 3-d seismic
                        technology for enhanced OneMedHQ systems and maximum
                        production
                      </p>
                      <span
                        className='cursor-pointer'
                        onClick={() => navigate('/technology/scicat')}
                      >
                        {' '}
                        Know More
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="bg-white advantages-sec">
          <div className="">
            <div className="box_content">
              <div className="row">
                {/* <div className="col-1" /> */}
                <div className="col-xl-6 col-sm-12 col-12">
                  <div
                    // data-aos-duration="700"
                    data-aos="fade-out"
                    // data-aos-delay="800"
                    className="title_4"
                  >
                    <h4>A Growing, Long-term Opportunity Set.</h4>
                    <p>
                      The interdisciplinary experts at OneMedHQ bring together
                      extensive experience in healthcare technology,
                      encompassing specialists in software development,
                      personalized patient care, and collaborative healthcare
                      solutions. Additionally, our team includes PhD researchers
                      dedicated to investigating strategic deployment locations
                      for cutting-edge healthcare technology, guaranteeing a
                      worldwide influence in areas bolstered by well-established
                      healthcare ecosystems and extensive data.
                    </p>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12 col-12">
                  <div
                    // data-aos-duration="700"
                    data-aos="fade-up"
                    // data-aos-delay="1000"
                    className=" d-flex justify-content-end"
                  >
                    <img className="w-75" src={acrossGlobe} alt=""></img>
                  </div>
                </div>
                {/* <div className="col-1" /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="middle_section_advantages">
          <div className="">
            <div className="row">
              <div
                // data-aos-duration="700"
                data-aos="fade-left"
                className="col-xl-6 col-sm-12 col-12"
              >
                <img src={leftImage} alt="" className="w-75"></img>
              </div>
              <div className="col-xl-6 col-sm-12 col-12">
                <div
                  // data-aos-duration="700"
                  data-aos="fade-right"
                  className="text_area_"
                >
                  <h3>
                    Most streamlined healthcare platform with the smallest
                    digital footprint and collaboration for every healthcare
                    stakeholder involved.
                  </h3>
                  <p>
                    OneMedHQ is steadfastly committed to integrating advanced
                    healthcare technologies into its operations, leveraging
                    innovative solutions to actively manage and enhance patient
                    experiences, thereby minimizing inefficiencies and improving
                    overall healthcare outcomes, in stark contrast to
                    traditional medical practices.
                  </p>
                  <div className="leftborder_">
                    <p>
                      {/* <span className=""></span> */}
                      OneMedHQ, a groundbreaking healthcare technology, has been
                      a transformative force in healthcare collaboration.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Row className='page-heading-wrap padding-0 growing-wrap'>
          <Col md='12'>
            <div
              data-aos-duration='700'
              data-aos='fade-up'
              data-aos-delay='1000'
            >
              <img className='responsive' src={growth} alt='growth' />
            </div>
          </Col>
        </Row> */}
        {/* <div>
          <div data-aos-duration="700" data-aos="fade-up">
            <img
              src={advantages_banner}
              alt="advantages_banner"
              style={{ width: "100%" }}
            />
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Advantages;
