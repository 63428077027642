const Team = [
  // {
  //   id: 9,
  //   name: "Rajan Ahuja",
  //   designation: "Operations",
  //   position: "Senior Executive Advisor",
  //   img_path: require("../../assets/img/team/rajan.png"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/rajan-ahuja-391b2840/",
  //   region: "USA"

  // },
  // {
  //   id: 11,
  //   name: "Mohan Anem",
  //   designation: "Finance",
  //   position: "Senior Advisor, Corporate Finance",
  //   img_path: require("../../assets/img/team/Mohan.png"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/mohananem/",
  //   region: "Europe"
  // },
  {
    id: 1,
    name: "Gabriela Balderas",
    designation: "Legal",
    position: "Paralegal",
    img_path: require("../../assets/img/team/Gabriela_Balderas.png"),
    category: "e&p",
    linkedIn: "https://www.linkedin.com/in/gabriela-balderas-11a796162/",
    region: "USA",
  },

  // {
  //   id: 6,
  //   name: "Doug Campbell",
  //   designation: "Advisor",
  //   position: "Senior Advisor",
  //   img_path: require("../../assets/img/team/Doug.png"),
  //   category: "innovation",
  //   linkedIn: "https://www.linkedin.com/in/doug-campbell/",
  //   region: "USA"

  // },
  {
    id: 2,
    name: "Ashima Chock",
    designation: "Legal",

    position: "Senior Legal Council",
    img_path: require("../../assets/img/team/ashima_arora.png"),
    category: "e&p",
    linkedIn: "https://www.linkedin.com/in/ashimachock/",
    region: "USA",
  },
  // {
  //   id: 19,
  //   name: "Jaian Cuttari",
  //   designation: "Advisor",
  //   position: "Senior Advisor",
  //   img_path: require("../../assets/img/team/jaian_new.png"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/jigish-dharaiya-66802950/",
  //   region: "India"

  // },
  // {
  //   id: 2,
  //   name: "Dr. Andrew Davidoff",
  //   designation: "Advisor",
  //   position: "Geology Advisor",
  //   img_path: require("../../assets/img/team/Andrew.png"),
  //   category: "capital",
  //   linkedIn: "https://www.linkedin.com/in/andrew-joseph-davidoff/",
  //   region: "USA"

  // },
  {
    id: 3,
    name: "Ali Deylami",
    designation: "Finance",

    position: "Head, Accounting",
    img_path: require("../../assets/img/team/ali_bw.png"),
    category: "e&p",
    linkedIn: "https://www.linkedin.com/",
    region: "USA",
  },
  // {
  //   id: 15,
  //   name: "Aaron Dirks",
  //   designation: "Business",
  //   position: "Co-Developer",
  //   img_path: require("../../assets/img/team/aaron.jpg"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/aarondirks/",
  //   region: "USA"

  // },
  // {
  //   id: 4,
  //   name: "Darrell Gordon",
  //   designation: "Finance",
  //   position: "Chief Financial Officer",
  //   img_path: require("../../assets/img/team/darrell.jpg"),
  //   category: "royalties",
  //   linkedIn: "https://www.linkedin.com/in/darrell-gordon-204871a8/",
  //   region: "USA"

  // },

  // {
  //   id: 10,
  //   name: "Curt Horne",
  //   designation: "Business",
  //   position: "Senior Land expert",
  //   img_path: require("../../assets/img/team/1.png"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/curthorne/",
  //   region: "USA"

  // },
  // {
  //   id: 8,
  //   name: "Manish Mishra",
  //   designation: "Business",
  //   position: "Business Development",
  //   img_path: require("../../assets/img/team/manish.png"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/manishmishraenergyallied/",
  //   region: "USA"

  // },
  {
    id: 4,
    name: "Dr. Jay Patel",
    designation: "founder",
    position: "Chairman",
    img_path: require("../../assets/img/team/jayy.png"),
    category: "capital",
    linkedIn: "https://www.linkedin.com/in/jay-patel-77795910b/",
    region: "USA",
  },

  {
    id: 5,
    name: "Mayur Patel",
    designation: "Operations",
    position: "Director - Head, Operations",
    img_path: require("../../assets/img/team/mayur_patel.png"),
    category: "royalties",
    linkedIn: "https://www.linkedin.com/in/mike-fenton-7484a148/",
    region: "USA",
  },

  {
    id: 6,
    name: "Vaibhav Patel",
    designation: "Business",
    position: "CEO",
    img_path: require("../../assets/img/team/vaibhav.png"),
    category: "e&p",
    linkedIn: "",
    region: "India",
  },

  {
    id: 7,
    name: "Agam Rao",
    designation: "Business",
    position: "Director",
    img_path: require("../../assets/img/team/agam.png"),
    category: "innovation",
    linkedIn: "",
    region: "USA",
  },

  {
    id: 8,
    name: "Deepak Sharma",
    designation: "",

    position: "Director",
    img_path: require("../../assets/img/team/deepak.png"),
    category: "e&p",
    linkedIn: "https://www.linkedin.com/in/deepak-sharma-4b21a1181/",
    region: "USA",
  },

  {
    id: 9,
    name: "Samir Thakkar",
    designation: "Finance",
    position: "Analyst",
    img_path: require("../../assets/img/team/samir.png"),
    category: "innovation",
    linkedIn: "",
    region: "USA",
  },

  {
    id: 10,
    name: "Ankur Trivedi",
    designation: "Business",
    position: "Business",
    img_path: require("../../assets/img/team/ankur.png"),
    category: "innovation",
    linkedIn: "",
    region: "USA",
  },
  {
    id: 11,
    name: "Parth Devariya",
    designation: "Tech",
    position: "Tech",
    img_path: require("../../assets/img/team/parth-devariya.png"),
    category: "innovation",
    linkedIn: "",
    region: "USA",
  },

  // {
  //   id: 11,
  //   name: "Sushim Shah",
  //   designation: "Finance",
  //   position: "Senior VP, Finance",
  //   img_path: require("../../assets/img/team/sushim_new.png"),
  //   category: "innovation",
  //   linkedIn: "https://www.linkedin.com/in/sushim-shah-88b141/",
  //   region: "USA",
  // },

  // {
  //   id: 3,
  //   name: "Dr. Michael Shoemaker",
  //   designation: "Advisor",
  //   position: "Geophysics Advisor",
  //   img_path: require("../../assets/img/team/michael.jpg"),
  //   category: "capital",
  //   linkedIn: "https://www.linkedin.com/in/dr-michael-shoemaker-71366531/",
  //   region: "USA"

  // },

  // {
  //   id: 13,
  //   name: "Keith Simpson",
  //   designation: "Advisor",
  //   position: "Senior Advisor",
  //   img_path: require("../../assets/img/team/keith.png"),
  //   category: "e&p",
  //   linkedIn: "",
  //   region: "USA"

  // },
  // {
  //   id: 16,
  //   name: "Troy von Otnott",

  //   designation: "Business",
  //   position: "Co-Developer",
  //   img_path: require("../../assets/img/team/von.jpg"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/troy-von-otnott-60bbb86/",
  //   region: "USA"

  // },

  // {
  //   id: 6,
  //   name: "Shane Quimby",
  //   position: "Chief Compliance Officer",
  //   img_path: require("../../assets/img/team/Shane.png"),
  //   category: "royalties",
  //   linkedIn: "https://www.linkedin.com/in/shane-quimby-561097a/",
  //   region:"USA"

  // },

  //  {
  //     id: 9,
  //     name: "Mayur Patel",
  //     designation:"Business",
  //     position: "Head, Operations",
  //     img_path: require("../../assets/img/team/treyy.png"),
  //     category: "e&p",
  //     linkedIn: "https://www.linkedin.com",

  //     region:"USA"

  //   },
  // {
  //   id: 9,
  //   name: "Trey Storer",
  //   designation:"Business",
  //   position: "Project Manager",
  //   img_path: require("../../assets/img/team/treyy.png"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/trey-storer/",
  //   region:"USA"

  // },

  // {
  //   id: 12,
  //   name: "Shannon Caraway",
  //   position: "Senior Business Development Manager",
  //   img_path: require("../../assets/img/team/shannon.png"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/shannon-caraway-01a0661a/",
  //   region:"USA"

  // },

  // {
  //   id: 16,
  //   name: "Kunal Sharma",
  //   position: "International Financial Advisor",
  //   img_path: require("../../assets/img/team/kunal.png"),
  //   category: "e&p",
  //   linkedIn: "",
  //   region:"Europe"

  // },
  // {
  //   id: 17,
  //   name: "Raj Patel",
  //   designation:"Business",
  //   position: "VP Business Development India",
  //   img_path: require("../../assets/img/team/Raj_Patel.jpg"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/rajpatelprofessional/",
  //   region:"India"

  // },
  // {
  //   id: 18,
  //   name: "Harish Mamtani",
  //   position: "Out Reach Expert",
  //   img_path: require("../../assets/img/team/Harish_Mamtani.png"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/harishmamtani/",
  //   region:"USA"

  // },

  // {
  //   id: 19,
  //   name: "Stuart Cohen",
  //   designation: "Finance",
  //   position: "Financial Advisor",
  //   img_path: require("../../assets/img/team/stuart.jpg"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/stuart-jay-cohen",
  //   region:"USA"

  // },
  // {
  //   id: 20,
  //   name: "Vaibhav Patel",
  //   designation: "Operations",
  //   position: "VP Government Liasion India",
  //   img_path: require("../../assets/img/team/vaibhav.png"),
  //   category: "e&p",
  //   linkedIn: "",
  //   region:"India"

  // },

  // {
  //   id: 23,
  //   name: "Floyd Griffith",
  //   designation: "Finance",
  //   position: "Financial Advisor",
  //   img_path: require("../../assets/img/team/floyd.png"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/floyd-h-griffith-7016a811b/",
  //   region:"USA"

  // },

  // {
  //   id: 26,
  //   name: "Jeremy Goodwin",
  //   position: "Project Manager",
  //   img_path: require("../../assets/img/team/jeremy.jpg"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/jeremy-goodwin-a64140269/",
  //   region:"USA"

  // },
  // {
  //   id: 27,
  //   name: "Manisha Gandhi",
  //   position: "Head of Communication",
  //   img_path: require("../../assets/img/team/manisha.jpg"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/manishacgandhi/",
  //   region:"USA"

  // },
  //  {
  //   id: 28,
  //   name: "Ahmed Malik",
  //   position: "Senior Power Negotiator",
  //   img_path: require("../../assets/img/team/ahmed.jpg"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/ahmed-malik-229a4617/",
  //   region:"USA"

  // },
  // {
  //   id: 29,
  //   name: "Tony Shaw",
  //   position: "Senior Geophysicist",
  //   img_path: require("../../assets/img/team/tony.png"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/tonyshaw/",
  //   region:"USA"

  // },

  // {
  //  id: 30,
  //name: "Mital Patel",
  //  designation:"Operations",
  //  position: "Operations Canada",
  //  img_path: require("../../assets/img/team/mital.jpg"),
  //  category: "e&p",
  //  linkedIn: "https://www.linkedin.com/in/mittal0801/",
  //  region:"Canada"

  //},
  // {
  //   id: 31,
  //   name: "Jigish Dharaiya",
  //   designation:"Operations",
  //   position: "VP Operations India",
  //   img_path: require("../../assets/img/team/jigish_dharaiya.png"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/jigish-dharaiya-66802950/",
  //   region:"India"

  // },
];

export default Team;
