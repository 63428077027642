import React, { useState, useEffect } from "react";
import InnerBanner from "../innerBanner";
// import ContactDetail from "./ContactDetail";
import Loader from "../layout/loader";
import contactBanner from "../../assets/img/onemedhq_images/contactusBanner.jpg";
import { Col, FormControl, FormLabel, Row, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import authService from "../services/authService";

const ContactUs = () => {
  const [data, setData] = useState({});
  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let handleSubmit = async (e) => {
    e.preventDefault();
    if (!data.firstName || !data.lastName || !data.email || !data.phone) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter mandatory field!",
      });
      return;
    }

    let dataDetails = {
      firstName: data.firstName,
      lasttName: data.lastName,
      email: data.email,
      phone: data.phone,
      subject: data.subject,
      message: data.message,
    };

    console.log(dataDetails);
    // try {
    //   await authService.contactDetails(dataDetails).then(res => {
    //     if (res.status) {
    //       document.getElementById('create-course-form').reset()

    //       Swal.fire({
    //         icon: 'success',
    //         title: 'Submitted',
    //         text: 'All the details are collected. We will contact you soon.'
    //       })
    //       setData({})
    //     } else {
    //       Swal.fire({
    //         icon: 'error',
    //         title: 'Error',
    //         text: 'Some error occured. Please try again leter.'
    //       })
    //     }
    //   })
    // } catch (err) {
    //   console.log(err)
    // }
  };
  return (
    <>
      {/* <center> */}
      <Loader />
      <InnerBanner
        title="CONTACT US"
        image="https://www.alfredhealth.org.au/images/general/Hero-banners/Contact_us-1644.jpg"
      />

      <div className="contact-form">
        <Row sm="12">
          <Col sm="12" md="6" className="p-4 text-center">
            <div className="contact-form-sumbit">
              <h3>Get in touch with us</h3>
            </div>
            <form>
              <Row className="g-4">
                <Col md="6" sm="3" className="text-start">
                  <FormLabel>First Name</FormLabel>
                  <FormControl
                    className="formInput"
                    placeholder="John"
                    name="firstName"
                    onChange={onChange}
                    required
                  />
                </Col>

                <Col md="6" className="text-start">
                  <FormLabel>Last Name</FormLabel>
                  <FormControl
                    className="formInput"
                    placeholder="Doe"
                    name="lastName"
                    onChange={onChange}
                    required
                  />
                </Col>

                <Col lg="6" md="12" sm="12" className="text-start">
                  <FormLabel>Email Address</FormLabel>
                  <FormControl
                    className="formInput"
                    type="email"
                    placeholder="johndoe@email.com"
                    onChange={onChange}
                    name="email"
                    required
                  />
                </Col>

                <Col lg="6" md="12" sm="12" className="text-start">
                  <FormLabel>Phone number</FormLabel>
                  <FormControl
                    className="formInput"
                    type="number"
                    placeholder="000 000 0000"
                    onChange={onChange}
                    name="phone"
                    required
                  />
                </Col>

                <Col md="12" className="text-start">
                  <FormLabel>Subject</FormLabel>
                  <FormControl
                    className="formInput"
                    placeholder="Enter your subject"
                    onChange={onChange}
                    name="subject"
                  />
                </Col>

                <Col md="12" className="text-start">
                  <FormLabel>Question</FormLabel>
                  <FormControl
                    className="formInput"
                    as="textarea"
                    placeholder="Type your message here"
                    onChange={onChange}
                    rows={6}
                    name="message"
                  />
                </Col>
                <Col md="12" className="text-start">
                  <button type="submit" className="btn submit-btn">
                    Submit
                  </button>
                </Col>
              </Row>
            </form>
          </Col>

          <Col sm="12" md="6" className="p-4 text-center ">
            <div className="contact-form-sumbit">
              <h3>Contact Information</h3>
            </div>
            <div class="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.9112940448167!2d72.50376677591214!3d23.02702901616065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b398e5880f1%3A0x32614b29d1226274!2sShivalik%20Shilp!5e0!3m2!1sen!2sin!4v1707557803883!5m2!1sen!2sin"
                // width="600"
                // height="394"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="contact_address">
              <h5>Address</h5>
              <center className="">
                <p style={{ fontWeight: "bold", textAlign: "center" }}>
                  USA Address:
                </p>
                <p
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",

                    textAlign: "center",
                    marginLeft: "10px",
                    // width: "20rem",
                  }}
                >
                  2150 Town Square Place, Suite 200, Sugar Land, Texas 77479
                </p>
              </center>
              <center className="mt-4">
                <p style={{ fontWeight: "bold", textAlign: "center" }}>
                  India Address:
                </p>
                <p
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",

                    textAlign: "center",
                    marginLeft: "10px",
                  }}
                >
                  807 Shivalik shilp, Opp ISKON mandir, On S G High way,
                  Ahmedabad, India
                </p>
              </center>
            </div>
            <div className="contact_email">
              <h5>Email</h5>
              <p style={{ textAlign: "center" }}>info@onemedhq.com</p>
            </div>
            <div className="contact_Investors"></div>
            <div className="contact_Investors_details">
              <h5>Vaibhav Patel</h5>
              <p>CEO</p>
              <p>vaibhav@onemedhq.com</p>
            </div>
          </Col>
        </Row>
        {/* <div className="col-xl-6 col-md-6 col-sm- col-xs-12 ">
          <div className="row">
            <div className="col-xl-12 col-sm-12 col-12 p-0">
              <div className="contact-field">
                <div className="row">
                  <div className="conatct-form-sumbit">
                    <h3>Get in touch with us</h3>
                    <div className="form-wrap">
                      <form onSubmit={handleSubmit} id="create-course-form">
                        <Row className="g-4">
                          <Col md="6" className="text-start">
                            <FormLabel>First Name</FormLabel>
                            <FormControl
                              className="formInput"
                              placeholder="John"
                              name="firstName"
                              onChange={onChange}
                              required
                            />
                          </Col>

                          <Col md="6" className="text-start">
                            <FormLabel>Last Name</FormLabel>
                            <FormControl
                              className="formInput"
                              placeholder="Doe"
                              name="lastName"
                              onChange={onChange}
                              required
                            />
                          </Col>

                          <Col md="6" className="text-start">
                            <FormLabel>Email Address</FormLabel>
                            <FormControl
                              className="formInput"
                              type="email"
                              placeholder="johndoe@email.com"
                              onChange={onChange}
                              name="email"
                              required
                            />
                          </Col>

                          <Col md="6" className="text-start">
                            <FormLabel>Phone number</FormLabel>
                            <FormControl
                              className="formInput"
                              type="number"
                              placeholder="000 000 0000"
                              onChange={onChange}
                              name="phone"
                              required
                            />
                          </Col>

                          <Col md="12" className="text-start">
                            <FormLabel>Subject</FormLabel>
                            <FormControl
                              className="formInput"
                              placeholder="Enter your subject for the inquiry"
                              onChange={onChange}
                              name="subject"
                            />
                          </Col>

                          <Col md="12" className="text-start">
                            <FormLabel>Question</FormLabel>
                            <FormControl
                              className="formInput"
                              as="textarea"
                              placeholder="Type you message here"
                              onChange={onChange}
                              rows={6}
                              name="message"
                            />
                          </Col>
                          <Col md="12">
                            <button type="submit" className="btn submit-btn">
                              Submit
                            </button>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
        {/* <div className='media-contact'>
              <div className='row'>
                <div className='col-xl-3 col-md-12 col-sm-12 col-12'>
                  <div className='media-heading'>
                    <h3>For Media Inquiry</h3>
                  </div>
                </div>
                <div className='col-xl-9 col-md-12 col-sm-12 col-12'>
                  <div className='row media-contact-details'>
                    <div className='col-lg-4 col-md-12 col-sm-12 col-12'>
                      <h3>Mayur Patel</h3>
                      <p>Head of Operations</p>
                    </div>
                    <div className='col-lg-4 col-md-12 col-sm-12 col-12 media-head-subtitle'>
                      <h3>Phone</h3>
                      <p>832-722-5020</p>
                    </div>
                    <div className='col-lg-4 col-md-12 col-sm-12 col-12 media-head-subtitle'>
                      <h3>Email</h3>
                      <p>mayur@onemedhq.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
        {/* </div> */}
        {/* </div> */}

        {/* <div className="col-xl-6 col-lg-6 col-md col-sm col-xs">
          <div className="row address_info_heading">
            <div className="col-sm-12 col-md-12 col-xl-12 col-12 ">
              <div className="contact_heading">
                <h3>Contact Information</h3>
              </div>
              <div class="map-container">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.9112940448167!2d72.50376677591214!3d23.02702901616065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b398e5880f1%3A0x32614b29d1226274!2sShivalik%20Shilp!5e0!3m2!1sen!2sin!4v1707557803883!5m2!1sen!2sin"
                  // width="600"
                  // height="394"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="contact_address">
                <h5>Address</h5>
                <center className="">
                  <p style={{ fontWeight: "bold", textAlign: "center" }}>
                    USA Address:
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      marginLeft: "10px",
                      width: "20rem",
                    }}
                  >
                    2150 Town Square Place, Suite 200, Sugar Land, Texas 77479
                  </p>
                </center>
                <center className="mt-4">
                  <p style={{ fontWeight: "bold", textAlign: "center" }}>
                    India Address:
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      marginLeft: "10px",
                      width: "20rem",
                    }}
                  >
                    807 Shivalik shilp, Opp ISKON mandir, On S G High way,
                    Ahmedabad, India
                  </p>
                </center>
              </div>
              <div className="contact_email">
                <h5>Email</h5>
                <p style={{ textAlign: "center" }}>info@onemedhq.com</p>
              </div>
              <div className="contact_Investors"></div>
              <div className="contact_Investors_details">
                <h5>Vaibhav Patel</h5>
                <p>CEO</p>
                <p>vaibhav@onemedhq.com</p>
              </div>
            </div> */}
        {/* <div className='col-sm-12 col-md-6 col-xl-6 col-6 '>
                <div className='contact_heading'>
                  <h3>Contact Information</h3>
                </div>
                <div className='contact_address'>
                  <h5>Address</h5>
                  <center>
                    <p style={{ textAlign: 'center' }}>
                      807 Shivalik shilp, Opp ISKON mandir, On S G High way, Ahmedabab
                    </p>
                  </center>
                </div>
                <div className='contact_email'>
                  <h5>Email</h5>
                  <p style={{ textAlign: 'center' }}>info@onemedhq.com</p>
                </div>
                <div className='contact_Investors'>
                </div>
                <div className='contact_Investors_details'>
                  <h5>Vaibhav Patel</h5>
                  <p>CEO</p>
                  <p>vaibhav@onemedhq.com</p>
                </div>
              </div> */}
        {/* </div> */}
        {/* </div> */}
      </div>
      {/* </div> */}
      {/* </div> */}
      {/* </center> */}
    </>
  );
};

export default ContactUs;
