import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../layout/loader";
import About from "../home/About";
import BannerContatctUs from "./BannerContactUs";
import Bottom from "../home/Bottom";
import Brief from "../home/Brief";
import Info from "../home/Info";
import Insights from "../home/Insights";
import Numbers from "../home/Numbers";
import Plants from "../home/Plants";
import Team from "../home/Team";
import Aos from "aos";
import card1 from "../../assets/img/homePageMiddleLeft.png";
import card2 from "../../assets/img/Mask_Group.png";
import rightimg from "../../assets/img/onemedhq_images/homePageTopRight.jpg";
import ban from "../../assets/img/onemedhq_images/homePageMiddleBanner.jpg";
import acrossGlobe from "../../assets/img/onemedhq_images/homePageBottomleft.jpg";

const DashboardPage = ({ onLearnMore, onLets }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  useEffect(() => {
    Aos.init();
  }, []);

  const LearnMore = () => {
    return navigate("/about-us");
  };
  const lets = () => {
    return navigate("/contact/us");
  };

  return (
    <>
      <Loader />
      <BannerContatctUs onLearnMore={LearnMore} onLets={lets} />
      <div className="main-body">
        <div className="future-text-wrap">
          <div className="banner_head">
            {/* <div className='row text-center banner_head_text'>
              <div
                data-aos-duration='700'
                data-aos='fade-up'
                data-aos-delay='100'
                className='col20'
              >
                <div className='title_ '>
                  <h5>2.9 GW</h5>
                  <p>TEXAS, USA</p>
                </div>
              </div>
              <div
                data-aos-duration='700'
                data-aos='fade-up'
                data-aos-delay='500'
                className='col20'
              >
                <div className='title_ '>
                  <h5>80 MW</h5>
                  <p>LOUISIANA, USA</p>
                </div>
              </div>
              <div
                data-aos-duration='700'
                data-aos='fade-up'
                data-aos-delay='200'
                className='col20'
              >
                <div className='title_ '>
                  <h5>100 MW</h5>
                  <p>UTTAR PRADESH, INDIA</p>
                </div>
              </div>
              <div
                data-aos-duration='700'
                data-aos='fade-up'
                data-aos-delay='300'
                className='col20'
              >
                <div className='title_ '>
                  <h5>100 MW</h5>
                  <p>UTTARAKHAND, INDIA</p>
                </div>
              </div>
              <div
                data-aos-duration='700'
                data-aos='fade-up'
                data-aos-delay='400'
                className='col20'
              >
                <div className='title_ '>
                  <h5>150 MW</h5>
                  <p>Hungary</p>
                </div>
              </div>
            </div> */}

            <div className="row gx-5 gy-5 future-statement">
              <div className="col-xl-6 col-sm-12 col-12 d-flex align-items-center">
                <div className="text_right">
                  <h5
                    // data-aos-duration='700'
                    data-aos="fade-right"
                    // data-aos-delay='1000'
                  >
                    Revolutionizing Healthcare Globally with Seamless
                    Connectivity for Enhanced Patient Experiences{" "}
                  </h5>
                  <p
                    // data-aos-duration='700'
                    data-aos="fade-left"
                    // data-aos-delay='1000'
                    className="mt-2 mb-2"
                  >
                    OneMedHQ (or OneMedHQ™) serves as a pivotal nexus in the
                    healthcare landscape, seamlessly connecting practitioners,
                    patients, pharmacies, laboratories, hospitals, and insurance
                    providers to revolutionize the industry globally, fostering
                    a future where collaborative efforts drive optimal care for
                    all.
                  </p>
                  <div className="home-text-box">
                    <p>
                      At OneMedHQ, we're not just linking healthcare - we're
                      creating a collaborative, patient-centric future
                    </p>
                  </div>
                  <br /> <br />
                  <a onClick={() => navigate("about/")} className="learn-more">
                    {" "}
                    Know more
                  </a>
                </div>
              </div>

              <div className="col-xl-6 col-sm-12 col-12">
                <div
                  // data-aos-duration='700'
                  data-aos="fade-up"
                  // data-aos-delay='1000'
                >
                  <div className="d-flex justify-content-end">
                    <img src={rightimg} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            // data-aos-duration='700'
            data-aos="fade-up"
            // data-aos-delay='1000'
            className="banner_head"
          >
            <div className="row mt-5">
              <div className="col-12 relative">
                <img src={ban} alt="" className="banner-dashboard"></img>
                <div className="developing-banner">
                  <h3 className="seperator-banner-text">
                    At OneMedHQ, we're Revolutionizing healthcare through
                    seamless collaboration for an efficient and patient-centric
                    future.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='bg-focus'>
          <div>
            <div className='banner_head mb-5'>
              <div className='row'>
                <div className='col-12'>
                  <div className='tech_'>
                    <div className='text-center'>
                      <span className='title_1'>OUR TECHNOLOGY</span>
                      <h6 className='title_2'>
                        Our proprietary SciCat Geo 3D seismic technology and
                        Organic Rankine Cycle turbines combine for efficient
                        OneMedHQ power production in sedimentary geology.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row gy-5 mt-5 mb-5'>
                <div className='col-md-6 col-sm-12 col-12'>
                  <div
                    data-aos-duration='700'
                    data-aos='fade-left'
                    className='card h-100'
                  >
                    <img src={card2} alt='...' />
                    <div className='card-body card__'>
                      <h5 className='card-title text-left'>Technology</h5>
                      <p className='card-text  focus-text'>
                        We have developed proprietary and award winning seismic
                        geomechanics technology that measures in-situ 3D minimum
                        horizontal stress which is the key rock parameter that
                        governs hydraulic fracture stimulation of tight geologic
                        formations, not only for enhanced energy production, but
                        for controlled fracture design and minimal footprint.
                      </p>
                      <span onClick={() => navigate('technology/scicat')}>
                        {' '}
                        Know More
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-sm-12 col-12'>
                  <div
                    data-aos-duration='700'
                    data-aos='fade-right'
                    className='card h-100'
                  >
                    <img src={card1} alt='...' />
                    <div className='card-body card__'>
                      <h5 className='card-title text-left'>
                        Organic Rankine Cycle (ORC) Plant
                      </h5>

                      <p className='card-text text-left focus-text'>
                        OneMedHQ Holdings uses state-of-the-art compact Organic
                        Rankine Cycle (ORC) OneMedHQ power plants that optimize
                        low-temperature OneMedHQ resources for maximum power
                        production and 98% uptime efficiency.
                      </p>
                      <span onClick={() => navigate('technology/orc')}>
                        {' '}
                        Know More
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="power-generation-info-wrap">
          <div className="box_content">
            {/* <div className='row power-numbering'>
              <div
                data-aos-duration='700'
                data-aos='fade-down'
                data-aos-delay='600'
                className='col-2'
              >
                <div className='sm_box'>
                  <h1>30+</h1>
                  <p>Years</p>
                </div>

                <div className='title_3'>OneMedHQ Plant Lifetime</div>
              </div>
              <div
                data-aos-duration='700'
                data-aos='fade-down'
                data-aos-delay='500'
                className='col-2'
              >
                <div className='sm_box'>
                  <h1>24/7</h1>
                  <p>Baseload Power</p>
                </div>
                <div className='title_3'>Dispatchable</div>
              </div>
              <div
                data-aos-duration='700'
                data-aos='fade-down'
                data-aos-delay='400'
                className='col-2'
              >
                <div className='sm_box'>
                  <h1>1%</h1>
                  <p>Solar</p>
                </div>
                <div className='title_3'>Surface Footprint​</div>
              </div>
              <div
                data-aos-duration='700'
                data-aos='fade-down'
                data-aos-delay='300'
                className='col-2'
              >
                <div className='sm_box'>
                  <h1>98%</h1>
                </div>
                <div className='title_3'>Plant Run Time</div>
              </div>
              <div
                data-aos-duration='700'
                data-aos='fade-down'
                data-aos-delay='200'
                className='col-2'
              >
                <div className='sm_box'>
                  <h1>1°</h1>
                  <p>per decade</p>
                </div>
                <div className='title_3'>Energy Decline Rate​</div>
              </div>
              <div
                data-aos-duration='700'
                data-aos='fade-down'
                data-aos-delay='100'
                className='col-2'
              >
                <div className='sm_box'>
                  <h1>~$.03</h1>
                </div>
                <div className='title_3'>Levelized Cost Of Energy (ICOE)​</div>
              </div>
            </div> */}
            <div className="row gx-4 gy-5">
              <div
                data-aos-duration="700"
                data-aos="fade-right"
                className="col-xl-6 col-sm-12 col-12"
              >
                <div className="">
                  <img
                    src={acrossGlobe}
                    className="bottom-dashbord-img"
                    alt=""
                  ></img>
                </div>
              </div>
              <div className="col-xl-6 col-sm-12 col-12 d-flex align-items-center">
                <div
                  data-aos-duration="700"
                  data-aos="fade-left"
                  className="title_4 d-grid"
                >
                  <h3>Expanding the Reach of OneMedHQ Worldwide</h3>
                  <p>
                    The dedicated team at OneMedHQ is a powerhouse of expertise,
                    boasting a collective experience of over 200 years in
                    healthcare innovation and technology. Our skilled
                    professionals span a spectrum of disciplines, including
                    software engineering, data analytics, telemedicine
                    specialists, and healthcare administration. The team's
                    comprehensive knowledge ensures the seamless integration of
                    transformative technology into the healthcare landscape,
                    enabling us to simplify complex workflows and foster
                    efficient collaboration.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
