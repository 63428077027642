import CookiePolicy from "./CookiePolicy";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Cookie from "universal-cookie";

const CookieModalOne = ({ close }) => {
  const navigate = useNavigate();
  const ref = useRef();
  const cookie = new Cookie();
  const expiresAt = 60 * 48;

  const handleModalAccept = () => {
    let date = new Date();
    date.setTime(date.getTime() + expiresAt * 60 * 1000);
    const option = { path: "/", expires: date };
    cookie.set("cookieHq", JSON.stringify(true), option);
    close(false);
  };

  const handleModalReject = () => {
    let date = new Date();
    date.setTime(date.getTime() + expiresAt * 60 * 1000);
    const option = { path: "/", expires: date };
    cookie.set("cookieHq", JSON.stringify(false), option);
    close(false);
  };

  useEffect(() => {
    console.log("---->", window.location.href.slice(0, -13));
    if (window.location.href.includes("/cookie-policy")) {
      close(false);
    }
  }, []);

  return (
    <div className="modalOne">
      <div className="cookieOneBody">
        <div className="bodyCookie">
          <div className="bodyCookietxt">
            <h5>
              {" "}
              This website uses cookies to enhance your digital experience. For
              additional details please visit
              <span>
                <a
                  className="anchorCookie"
                  // onClick={() => navigate("/cookie-policy")}
                  onClick={() => window.open(`/cookie-policy`)}
                >
                  {" "}
                  Cookie Policy.
                </a>{" "}
              </span>
            </h5>
          </div>
          <div className="bodyCookiebtn">
            <div className="acceptAll">
              <button
                onClick={() => handleModalAccept()}
                className="btn cookieOneBtn"
              >
                Accept All
              </button>
            </div>
            <div className="rejectAll">
              <button
                onClick={() => handleModalReject()}
                className="btn cookieOneBtn"
              >
                Reject All Cookies
              </button>
            </div>
          </div>
          {/* <div className="bodyCookiebtn">
            <button
              onClick={() => handleModalAccept()}
              className="btn cookieOneBtn"
            >
              Reject All
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CookieModalOne;
